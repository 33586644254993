<!--

  Author: 
  components: { BgFormTracker },dhersey 11/04/2024
  Desc: Component for "Plan your Voyage" multi-step inquiry form
    for new Adventure Cruising 2024 project

-->

<template>

  <div class="navigation-wrapper">
    <form-tracker :step="currentStep"/>
  </div>

  {{ selectedDestinations }}

  <!-- 1 -->
  <section class="destinations-wrapper" :class="isActiveStep(0)">
    <destinations @close="(i) => handleNext(i)" />
  </section>

  <!-- 2 -->
  <section class="timeframe-wrapper" :class="isActiveStep(1)">
    <timeframe :destinations="selectedDestinations" />
  </section>

  <!-- 3 -->
  <section class="activities-wrapper" :class="isActiveStep(2)">
    activities
  </section>

  <!-- 4 -->
  <section class="contact-wrapper" :class="isActiveStep(3)">
    contact
  </section>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import FormTracker from './FormTracker.vue';
  import Destinations from './Destinations.vue';
  import Timeframe from './Timeframe.vue';

  //count += i

  let isDebug = false;
  let isLoading = ref(true);

  let currentStep = ref(0);  

  let selectedDestinations = ref([])

  function handleNext(i){
    selectedDestinations.value = i;
    currentStep.value += 1;
  }
  
  function isActiveStep(i){
    if (i == currentStep.value) {
      return 'active';
    } else {
      return;
    }
  }

</script>

<style scoped lang="scss">
@import '../../../../assets/stylesheets/v3/abstracts/colors';
  
section{
  &:not(.active){
    position: absolute;
    top: -9999px;
    left: -9999px;
    display: hidden;
  }
}

</style>