<template>
  <div>
    <ul>
      <li v-for="item in destinationsValue" :key="item">{{ item }}</li>
    </ul>
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  const props = defineProps(['destinations']);
  const destinationsValue = ref(props.destinations);

  watch(() => props.destinations, (newValue, oldValue) => {
    console.log('Prop Changed: ', newValue);
    destinationsValue.value = newValue;
  }); 
</script>

<style scoped lang="scss">

</style>